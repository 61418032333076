import { addUserEmailDomain } from '@thriveglobal/thrive-web-core'

const THRIVE_TEMP_USER_DOMAIN = 'Thrive-temp-user-domain'

export function getEmailDomainFromEmail(email: string) {
    if (!email) {
        return null
    }
    // Naive attempt to parse email
    const emailParts = email.toLowerCase().split('@')
    if (emailParts.length <= 1) {
        return null
    }
    return emailParts[emailParts.length - 1]
}

function saveEmailDomainToStorage(domain: string) {
    // Store domain temporarily in session storage so we can access again after sso redirect
    sessionStorage.setItem(THRIVE_TEMP_USER_DOMAIN, domain)
}

function getEmailDomainFromStorage() {
    return sessionStorage.getItem(THRIVE_TEMP_USER_DOMAIN)
}

export function addEmailDomainToLoggingPlatform(email?: string) {
    try {
        const emailDomain =
            getEmailDomainFromEmail(email) || getEmailDomainFromStorage()
        if (emailDomain) {
            addUserEmailDomain(emailDomain)
            saveEmailDomainToStorage(emailDomain)
        }
    } catch (error) {
        // Ignore since adding email domain to logging platform is only a nice to have
    }
}
